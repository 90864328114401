/* eslint-disable */

export const createLinks = (data, clickedMethod, tv_radio = false) => {
    let size = data.length
    let i=0
    let links = data.map((info) => {
        // check the link is drive or not
        const split_link = info[0].split("/")
        let is_drive = ((split_link[2] === 'drive.google.com') || (split_link[2] === 'www.youtube.com') || (split_link[2] === 'www.facebook.com')) ? true : false
        let img_url
        // replacing the hard coded url by env file link url
        if(tv_radio){
            img_url = process.env.REACT_APP_VIDEO_URL + info[0].split(".com")[1]
        } else {
            img_url = process.env.REACT_APP_LINK_URL + info[0].split(".com")[1]
        }

        i++
        return (
            <>
                {is_drive ? (
                    <a href={info[0]} target="_blank" className="px-1 text-primary cursor-pointer">
                        {info[1]}
                    </a>
                ):(
                    <span  
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => clickedMethod({url: img_url, media: info[1]})}
                        href={img_url} target="_blank" className="px-1 text-primary cursor-pointer single-alert-details">
                            {info[1]}
                    </span>
                )}
                
                { (i==size) ? "" : (<span style={{borderRight:"2px solid #A4A4A5"}}></span>)}
            </>
        );
    })
    return links
}